import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { MdDelete } from 'react-icons/md';
import AddAdminAuth from '@/components/superadminboard/AddAdminAuth';
import DatabaseContext from '@/contexts/DatabaseContext';
import Footer from '@/components/Footer';
import PopConfirmModal from '../../modals/PopConfirmModal';
import LoadingScreen from '../../components/router/LoadingScreen';
import TopNavbar from '../../components/dashboard/TopNavbar';

const SuperAdminboard = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { updateUserInfo } = useContext(DatabaseContext);

  useEffect(() => {
    const usersRef = 'users';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(usersRef)
      .orderByChild('email')
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const usersArr = [];
          const data = snapshot.val();
          Object.keys(data).forEach((key) => {
            if (data[key].email) {
              usersArr.push(data[key]);
            }
          });
          setUsers(usersArr);
        }
      });

    return () => {
      firebase.database().ref(usersRef).off();
    };
  }, []);

  const deleteAdminAuth = async (uid) => {
    const userInfo = users.find((x) => x.uid === uid);
    userInfo.isAdmin = false;
    await updateUserInfo(userInfo);
  };

  const adminUsers = users.filter((x) => x.isAdmin);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('superAdminboard.title')} | {t('shared.appName')}
          </title>
        </Helmet>

        <TopNavbar />

        <div className="container mt-12 px-12 xl:px-0">
          <AddAdminAuth users={users.filter((x) => !x.isAdmin)} />
          {adminUsers.length > 0 ? (
            <table className="text-lg table-auto ml-auto mr-auto">
              <caption className="px-4 py-2 text-xl font-bold">
                Admin List
              </caption>
              <thead>
                <tr>
                  <th className="px-4 py-2 text-emerald-600 border-solid border-2 border-white">
                    Email
                  </th>
                  <th className="px-4 py-2 text-emerald-600 border-solid border-2 border-white">
                    Remove Admin Permission
                  </th>
                </tr>
              </thead>
              <tbody>
                {adminUsers.map((x) => (
                  <tr key={x.uid}>
                    <td className="border-solid border-2 border-white px-4 py-2 text-emerald-600 font-medium">
                      {x.email}
                    </td>
                    <td className="border-solid border-2 border-white px-4 py-2 text-emerald-600 font-medium">
                      <PopConfirmModal
                        title="Are you sure?"
                        onConfirm={() => {
                          deleteAdminAuth(x.uid);
                        }}
                      >
                        <MdDelete className="m-auto" />
                      </PopConfirmModal>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1 className="font-bold">No Admin Users</h1>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SuperAdminboard;
