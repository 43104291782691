import React, { memo, useContext } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import Button from '@/components/shared/Button';
import ModalContext from '@/contexts/ModalContext';

const AddAdminAuth = ({ users }) => {
  const { emitter, events } = useContext(ModalContext);

  const handleClick = () => {
    emitter.emit(events.ADD_ADMIN_AUTH_MODAL, users);
  };

  return (
    <Button
      outline
      icon={AiOutlineUserAdd}
      onClick={handleClick}
      className="m-auto mb-8"
    >
      Add Admin Permission
    </Button>
  );
};

export default memo(AddAdminAuth);
